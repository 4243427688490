import {Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss',

    ]
})
export class ButtonGroupComponent{

    @Input() buttonList: any[] = [];
    @Output() onClick = new EventEmitter<any>();
    @ViewChildren('dd') dds: QueryList<NgbDropdown>;


  constructor(
    ) {

    }

    onClickButton(button: any){
        this.onClick.emit(button);
    }

}
