<!-- <div class="btn-group" role="group">
    <ng-container  *ngFor="let button of buttonList">
        <app-basic-button [iconClass]="button.iconClass"
            (onClickEvent)="onClickButton(button)"
            [classList]="button.classList">
            {{button.text|titlecase}}
        </app-basic-button>
    </ng-container>
</div> -->
<ng-template #nodeTemplateRef let-node="node">

  <li #dd="ngbDropdown" class="dropdown-submenu dropend" ngbDropdown  placement="left" display="dynamic" [autoClose]="'outside'" container="body">
    <a *ngIf="node['children']" class="dropdown-item" ngbDropdownToggle>
      {{ node.text }}
    </a>
    <a *ngIf="node['children'] === undefined" class="dropdown-item" (click)="onClickButton(node)">
      {{ node.text }}
    </a>
    <ul *ngIf="node.children" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
      <ng-template ngFor [ngForOf]="node.children" let-child>
        <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: child }">
        </ng-template>
      </ng-template>
    </ul>
  </li>

</ng-template>
<li style="list-style-type: none" #dd="ngbDropdown" ngbDropdown [autoClose]="'outside'" placement="bottom-left" display="dynamic" container="body" *ngIf="buttonList && buttonList.length>0">
    <button id="button-container" ngbDropdownToggle type="button" class="btn btn-crear-action"
        aria-controls="dropdown-container">Acciones <i class="fa-solid fa-angle-down ms-2"></i></button>
    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
        @for(button of buttonList; track button){
            <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: button }">
            </ng-template>

        }
    </ul>
</li>
